import { DEFAULT_GRID_CARD_SPACING } from './settingsConstants';
import { FontTheme } from '../FontTheme';
import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/yoshi-flow-editor/tpa-settings';
import {
  doNotTransformSettingsKey,
  transformSettingsKey,
} from '../../utils/utils';

export type IStylesParams = {
  /**
   * Layout styles
   */
  groupCardGridSpacing: IStyleParam<StyleParamType.Number>;
  // TODO: do we really need this, seems like never used
  groupCardGridInnerSideSpacing: IStyleParam<StyleParamType.Number>;
  groupCardGridTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardGridSideSpacing: IStyleParam<StyleParamType.Number>;

  groupCardListSpacing: IStyleParam<StyleParamType.Number>;
  // TODO: do we really need this, seems like never used
  groupCardListInnerSideSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListSideSpacing: IStyleParam<StyleParamType.Number>;

  groupCardListWidgetSpacing: IStyleParam<StyleParamType.Number>;
  // TODO: do we really need this, seems like never used
  groupCardListWidgetInnerSideSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListWidgetTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListWidgetSideSpacing: IStyleParam<StyleParamType.Number>;

  cardSideBySideHeight: IStyleParam<StyleParamType.Number>;

  /**
   * Design styles
   */
  listTitleColor: IStyleParam<StyleParamType.Color>;
  listTitleFont: IStyleParam<StyleParamType.Font>;
  mobileListTitleFontSize: IStyleParam<StyleParamType.Number>;
  groupNameColor: IStyleParam<StyleParamType.Color>;
  groupNameFont: IStyleParam<StyleParamType.Font>;
  mobileGroupNameFontSize: IStyleParam<StyleParamType.Number>;
  groupNameColorOnHover: IStyleParam<StyleParamType.Color>;
  searchSortingColor: IStyleParam<StyleParamType.Color>;
  groupInfoColor: IStyleParam<StyleParamType.Color>;
  groupInfoFont: IStyleParam<StyleParamType.Font>;
  mobileGroupInfoFontSize: IStyleParam<StyleParamType.Number>;
  buttonColor: IStyleParam<StyleParamType.Color>;
  buttonBackground: IStyleParam<StyleParamType.Color>;
  buttonTextColor: IStyleParam<StyleParamType.Color>;
  buttonTextFont: IStyleParam<StyleParamType.Font>;
  mobileButtonTextFontSize: IStyleParam<StyleParamType.Number>;
  buttonBorderRadius: IStyleParam<StyleParamType.Number>;
  cardBackgroundColor: IStyleParam<StyleParamType.Color>;
  appBackgroundColor: IStyleParam<StyleParamType.Color>;
  cardBorderColor: IStyleParam<StyleParamType.Color>;
  cardBorderWidth: IStyleParam<StyleParamType.Number>;
};
export const stylesParams: IStylesParams = {
  /**
   * Layout styles
   */
  groupCardGridSpacing: {
    key: 'groupCardGridSpacing',
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  // TODO: do we really need this, seems like never used
  groupCardGridInnerSideSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardGridInnerSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardGridTopBottomSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardGridTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardGridSideSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardGridSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardListSpacing: {
    getDefaultValue: () => 12,
    name: 'groupCardListSpacing',
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  // TODO: do we really need this, seems like never used
  groupCardListInnerSideSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardListInnerSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardListTopBottomSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardListTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardListSideSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardListSideSpacing',
    type: StyleParamType.Number,
  },
  // TODO:migration mention mobile key for groupCardListWidgetSpacing was absent before
  groupCardListWidgetSpacing: {
    getDefaultValue: () => 0,
    key: 'groupCardListSpacing',
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  // TODO: do we really need this, seems like never used
  groupCardListWidgetInnerSideSpacing: {
    getDefaultValue: () => 0,
    key: 'groupCardListInnerSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardListWidgetTopBottomSpacing: {
    getDefaultValue: () => 20,
    key: 'groupCardListTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardListWidgetSideSpacing: {
    getDefaultValue: () => 0,
    key: 'groupCardListSideSpacing',
    type: StyleParamType.Number,
  },
  cardSideBySideHeight: {
    getDefaultValue: () => 241,
    key: 'cardSideBySideHeight',
    type: StyleParamType.Number,
  },

  /**
   * Design styles
   */
  listTitleColor: {
    key: 'listTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('black/white'),
  },
  listTitleFont: {
    key: 'listTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_1, {
      size: 24,
      htmlTag: 'h1',
    }),
  },
  mobileListTitleFontSize: {
    key: 'mobileListTitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  groupNameColor: {
    key: 'groupNameColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('black/white'),
  },
  groupNameFont: {
    key: 'groupNameFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_3, { size: 20 }),
  },
  mobileGroupNameFontSize: {
    key: 'mobileGroupNameFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  groupNameColorOnHover: {
    key: 'groupNameColorOnHover',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  searchSortingColor: {
    key: 'searchSortingColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('black/white'),
  },
  groupInfoColor: {
    key: 'groupInfoColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupInfoFont: {
    key: 'groupInfoFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, { size: 14 }),
  },
  mobileGroupInfoFontSize: {
    key: 'mobileGroupInfoFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  buttonColor: {
    key: 'buttonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBackground: {
    key: 'buttonBackground',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonTextColor: {
    key: 'buttonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('white/black'),
  },
  buttonTextFont: {
    key: 'buttonTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2),
  },
  mobileButtonTextFontSize: {
    key: 'mobileButtonTextFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  buttonBorderRadius: {
    getDefaultValue: () => 100,
    key: 'buttonBorderRadius',
    type: StyleParamType.Number,
  },
  cardBackgroundColor: {
    key: 'cardBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  appBackgroundColor: {
    key: 'appBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cardBorderColor: {
    key: 'cardBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cardBorderWidth: {
    key: 'cardBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
};
