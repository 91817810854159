import React from 'react';
import { UserException, UserStatus } from '../../context/user/IUserContext';
import { useUser } from '../../context/user/useUser';
import { MembershipQuestionsModal } from '../MembershipQuestionsModal/MembershipQuestionsModal';
import { ProfilePrivacyDialog } from '../PrivacyDialog';

import { RegisterEventsDialog } from '../Restriction/events/RegisterEventsDialog';
import {
  answerToV2,
  questionToV1,
} from '../../api/model/MembershipQuestionAnswer/adapter';
import { ApiTypesV1QuestionIdAnswer } from '@wix/social-groups-api';
import { ApiErrorCode } from '../../../components/Group/controllers/errorHandler/IErrorEvent';
import { PlansDialog } from '../Restriction/plans/PlansDialog';
import { FuturePlansDialog } from '../Restriction/plans/FuturePlansDialog';

interface Props {}

export const UserExceptionBoundary: React.FC<Props> = () => {
  const { userActions, userStatus, userRequestResponse } = useUser();

  const privateProfile = userStatus === UserStatus.PRIVATE_PROFILE;
  const questionsMissing =
    userRequestResponse?.exception === UserException.MEMBERS_QUESTIONS_MISSING;
  const eventsRestricted =
    userRequestResponse?.exception ===
    ApiErrorCode.ACCESS_RESTRICTION_NOT_SATISFIED;
  const plansRestricted =
    userRequestResponse?.exception ===
    ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS;
  const futurePlanExists =
    userRequestResponse?.exception ===
    ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS;

  const submitAnswers = (answers: ApiTypesV1QuestionIdAnswer[]) => {
    userActions.changeGroupMembership(
      userRequestResponse?.group!,
      answers.map(answerToV2),
    );
  };
  const onRequestClose = () => userActions.ignoreException(); // this is to NOT pass event to controller
  const questions = React.useMemo(
    () => userRequestResponse?.questions?.map(questionToV1),
    [userRequestResponse?.questions],
  );
  return (
    <>
      {privateProfile && (
        <ProfilePrivacyDialog
          onRequestClose={onRequestClose}
          onChangeProfile={() => userActions.makeProfilePublic()}
          isOpen={
            userRequestResponse?.exception ===
            UserException.PRIVATE_PROFILE_RESTRICTION
          }
        />
      )}
      <MembershipQuestionsModal
        isOpen={questionsMissing}
        questions={questions!}
        groupId={userRequestResponse?.group.id!}
        groupName={userRequestResponse?.group.title!}
        onSubmit={submitAnswers}
        onViewGroupClick={() => {
          throw new Error('Method not implemented.');
        }}
        onRequestClose={onRequestClose}
      />
      {eventsRestricted && (
        <RegisterEventsDialog
          isOpen={eventsRestricted}
          onRequestClose={onRequestClose}
        />
      )}
      {plansRestricted && (
        <PlansDialog isOpen={plansRestricted} onRequestClose={onRequestClose} />
      )}
      {futurePlanExists && (
        <FuturePlansDialog
          isOpen={futurePlanExists}
          onRequestClose={onRequestClose}
        />
      )}
    </>
  );
};

UserExceptionBoundary.displayName = 'UserExceptionBoundary';

//  handleViewGroupClick = (groupId: string) => {
//     const { actions, bi } = this.props;
//     bi.report(
//       groupsSelectGroupFromList({
//         componentName: 'groups',
//         pageName: 'member_questions_screen',
//         origin: 'member_questions_screen_link',
//         groupId,
//       }),
//     );
//     actions.goToGroup(groupId);
//   };
//  // TODO: move
